<template>
    <div>
        <el-dialog
            title="单词详情"
            :visible.sync="show"
            width="50%"
            :before-close="closeFn"
        >
            <el-form
                label-width="110px"
                :model="editWordDetailForm"
                ref="editWordDetailFormRef"
                :rules="editWordDetailFormRules"
                :inline="true"
            >
                <!-- 单词 -->
                <el-row>
                    <el-form-item label="单词:" prop="spell">
                        <el-input
                            style="width: 100%"
                            v-model="editWordDetailForm.spell"
                            placeholder="请输入"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="单词发音:" prop="purl">
                        <el-input
                            style="width: 100%"
                            v-model="editWordDetailForm.purl"
                            placeholder="请输入"
                            clearable
                        ></el-input>
                    </el-form-item>
                </el-row>
                <!-- 定义 -->
                <el-row>
                    <el-form-item label="定义:" prop="def">
                        <el-input
                            style="width: 100%"
                            v-model="editWordDetailForm.def"
                            placeholder="请输入"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="定义发音:" prop="durl">
                        <el-input
                            style="width: 100%"
                            v-model="editWordDetailForm.durl"
                            placeholder="请输入"
                            clearable
                        ></el-input>
                    </el-form-item>
                </el-row>
                <!-- 例句 -->
                <el-row>
                    <el-form-item label="例句:" prop="exp">
                        <el-input
                            style="width: 100%"
                            v-model="editWordDetailForm.exp"
                            placeholder="请输入"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="例句发音:" prop="eurl">
                        <el-input
                            style="width: 100%"
                            v-model="editWordDetailForm.eurl"
                            placeholder="请输入"
                            clearable
                        ></el-input>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="词性:" prop="partSpeech">
                        <el-input
                            style="width: 100%"
                            v-model="editWordDetailForm.partSpeech"
                            placeholder="请输入"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="词性发音:" prop="partUrl">
                        <el-input
                            style="width: 100%"
                            v-model="editWordDetailForm.partUrl"
                            placeholder="请输入"
                            clearable
                        ></el-input>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="音标:" prop="phonetic">
                        <el-input
                            style="width: 100%"
                            v-model="editWordDetailForm.phonetic"
                            placeholder="请输入"
                            clearable
                        ></el-input>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="组别:" prop="level">
                        <el-select
                            placeholder="请选择"
                            style="width: 100%"
                            disabled
                            v-model="editWordDetailForm.level"
                            clearable
                        >
                            <el-option
                                v-for="item in dict_Group"
                                :key="item.dictCode"
                                :label="item.dictLabel"
                                :value="item.dictValue"
                            ></el-option>
                        </el-select> </el-form-item
                >          <el-form-item label="试卷难度:" prop="difficulty">
                    <el-select v-model="editWordDetailForm.difficulty">
                        <el-option
                            v-for="item in difficulty_type"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item></el-row>
            </el-form>
            <el-row type="flex" justify="center" style="margin-top: 20px">
                <el-col :span="4">
                    <el-button type="primary" style="width: 100%" @click="save"
                        >保存</el-button
                    >
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
import { saveWordDetail } from '@/http/api'
export default {
  name: 'wordAddEdit',
  props: {
    show: Boolean,
    title: String,
    editWordDetailForm: Object
  },
  data () {
    return {
      difficulty_type: this.$userInfo.difficulty_type(),
      dict_Group: this.$userInfo.dict_group(),
      AudioIsPlay: false,
      editWordDetailFormRules: {
        difficulty: [{ required: true, message: '请选择', trigger: 'change' }],
        spell: [{ required: true, message: '请输入', trigger: 'blur' }],
        def: [{ required: true, message: '请输入', trigger: 'blur' }],
        exp: [{ required: true, message: '请输入', trigger: 'blur' }],
        purl: [{ required: true, message: '请输入', trigger: 'blur' }],
        durl: [{ required: true, message: '请输入', trigger: 'blur' }],
        eurl: [{ required: true, message: '请输入', trigger: 'blur' }],
        partSpeech: [{ required: true, message: '请输入', trigger: 'blur' }],
        partUrl: [{ required: true, message: '请输入', trigger: 'blur' }],
        level: [{ required: true, message: '请选择', trigger: 'change' }]
      }
    }
  },

  methods: {
    submitFn () {
      this.$emit('submit')
    },
    closeFn () {
      this.$refs.editWordDetailFormRef.resetFields()
      this.$emit('close')
    },
    save () {
      this.$refs.editWordDetailFormRef.validate(async valid => {
        if (!valid) return
        saveWordDetail(this.editWordDetailForm).then((res) => {
          if (res.code === 200) {
            this.$message.success('保存成功')
            this.closeFn()
          }
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
.voiceStrClass {
    font-size: 16px;
}
.changeVoiceBack {
    display: flex;
    align-items: center;
}
.playVoiceImageClass {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}
.wordImg {
    widows: 100px;
    height: 100px;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}
</style>
