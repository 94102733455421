<template>
    <div>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item
                :to="{
                    path: '/academic_Management/academic_font_management/academic_font_management_home',
                }"
                >学术系统</el-breadcrumb-item
            >
            <el-breadcrumb-item>蜂Talk管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">条件筛选</span>
                </el-col>
            </el-row>
            <el-form ref="queryFormRef" :model="queryForm" label-width="80px">
                <el-row :gutter="50">
                    <el-col :span="6">
                        <el-form-item label="赛季" prop="season">
                            <el-select
                                placeholder="请选择类型"
                                style="width: 100%"
                                v-model="queryForm.season"
                            >
                                <el-option
                                    v-for="item in dict_season"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select> </el-form-item
                    ></el-col>
                    <el-col :span="6">
                        <el-form-item label="单词" prop="spell">
                            <el-input
                                v-model="queryForm.spell"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="6">
                        <el-form-item label="组别" prop="level">
                            <el-select
                                placeholder="请选择"
                                v-model="queryForm.level"
                                disabled
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in dict_Group"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="6">
                        <el-form-item label="难度" prop="difficulty">
                            <el-select
                                placeholder="请选择"
                                v-model="queryForm.difficulty"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in difficulty_type"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <el-col :span="2">
                    <el-button
                        type="primary"
                        style="width: 100%"
                        @click="searchResult"
                        >查询</el-button
                    >
                </el-col>
                <el-col :span="2">
                    <el-button
                        type="primary"
                        plain
                        style="width: 100%"
                        @click="reset"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <!-- 列表 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col :span="5">
                    <span class="title_class">单词管理</span>
                </el-col>
                <el-col :span="19" style="text-align: right">
                    <el-button
                        type="primary"
                        plain
                        @click="importWordExcelDialogVisible = true"
                        icon="el-icon-upload2"
                        >批量导入</el-button
                    >
                    <el-button
                        type="primary"
                        plain
                        @click="downloadInfoList"
                        icon="el-icon-download"
                        >批量导出</el-button
                    >
                    <el-button
                        type="primary"
                        @click="editDistrictMessage('add')"
                        >新增单词+</el-button
                    >
                    <el-button
                        type="primary"
                        @click="emptyWordExcelDialogVisible = true"
                        >清空</el-button
                    >
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column label="单词" prop="spell">
                    <template slot-scope="scope">
                        <el-link
                            style="color: #409eff"
                            @click="handleToWordVoice(scope.row.purl)"
                            >{{ scope.row.spell }}</el-link
                        >
                    </template>
                </el-table-column>
                <el-table-column label="词性" prop="partSpeech"
                    ><template slot-scope="scope">
                        <el-link
                            style="color: #409eff"
                            @click="handleToWordVoice(scope.row.partUrl)"
                            >{{ scope.row.partSpeech }}</el-link
                        >
                    </template></el-table-column
                >
                <el-table-column
                    label="定义"
                    prop="def"
                    :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <el-link
                            style="color: #409eff"
                            @click="handleToWordVoice(scope.row.durl)"
                            >{{ scope.row.def }}</el-link
                        >
                    </template></el-table-column
                >
                <el-table-column
                    label="例句"
                    prop="exp"
                    :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <el-link
                            style="color: #409eff"
                            @click="handleToWordVoice(scope.row.eurl)"
                            >{{ scope.row.exp }}</el-link
                        >
                    </template></el-table-column
                >
                <el-table-column label="音标" prop="phonetic">
                </el-table-column>
                <el-table-column
                    label="组别"
                    prop="enrollmentLevel"
                    :formatter="groupFormatter"
                ></el-table-column>
                <el-table-column
                    label="难度"
                    prop="difficulty"
                    :formatter="difficultyFormatter"
                ></el-table-column>
                <!-- <el-table-column
                    label="入库时间"
                    prop="indataAt"
                ></el-table-column> -->
                <el-table-column label="操作" width="140px">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="mini"
                            @click="editDistrictMessage('edit', scope.row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            size="small"
                            style="color: #f56c6c"
                            @click="deleteDialog(scope.row.id)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <audio ref="audioPlayer" @ended="overAudio"></audio>
        <!-- 批量导入英文单词的Dialog -->
        <el-dialog
            title="批量导入数据"
            :visible.sync="importWordExcelDialogVisible"
            width="30%"
            @close="importWordExcelDialogClose"
        >
            <el-form
                :model="importQueryForm"
                label-width="100px"
                ref="importWordInfoFormRef"
                :rules="importWordInfoRules"
                style="width: 100%"
            >
                <el-form-item label="组别" prop="level">
                    <el-select v-model="importQueryForm.level" disabled>
                        <el-option
                            v-for="item in dict_Group"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-upload
                    :action="importWordExcel.uploadUrl"
                    ref="importWordExcelRef"
                    :data="importQueryForm"
                    :headers="importWordExcel.headerObj"
                    :on-progress="importWordExcelProgress"
                    :on-success="importWordExcelSuccess"
                    :on-error="importWordExcelError"
                    :on-exceed="importWordExcelExceed"
                    :on-change="onChangeLoad"
                    :limit="1"
                    :auto-upload="false"
                >
                    <el-button slot="trigger" size="small" type="primary"
                        >选取文件</el-button
                    >
                    <el-button
                        style="margin-left: 10px"
                        type="primary"
                        size="small"
                        @click="downloadWordExcelTemplate"
                        >下载模版</el-button
                    >
                    <el-button
                        style="margin-left: 10px"
                        size="small"
                        type="success"
                        @click="uploadWordExcel"
                        >上传词库信息</el-button
                    >
                </el-upload>
            </span>
        </el-dialog>
        <!-- 清空组别单词的Dialog -->
        <el-dialog
            title="清空单词"
            :visible.sync="emptyWordExcelDialogVisible"
            width="30%"
            @close="emptyWordExcelDialogClose"
        >
            <el-form
                :model="importQueryForm"
                label-width="100px"
                ref="emptyWordInfoFormRef"
                :rules="importWordInfoRules"
                style="width: 100%"
            >
                <el-form-item label="组别" prop="level">
                    <el-select v-model="importQueryForm.level" disabled>
                        <el-option
                            v-for="item in dict_Group"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="难度" prop="difficulty">
                    <el-select
                        placeholder="请选择"
                        v-model="importQueryForm.difficulty"
                    >
                        <el-option
                            v-for="item in difficulty_type"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="emptyWordExcelDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="saveEmptyQueryClick"
                    >保 存</el-button
                >
            </span>
        </el-dialog>
        <wordDetaiEdit
            :show="editCharactersDialogVisible"
            :editWordDetailForm="editWordDetailForm"
            @close="closeFn"
        ></wordDetaiEdit>
    </div>
</template>

<script>
import { getCityQuestionWordList, deleteCityQuestionWord, emptyCityQuestionWord, cityQuestionWord } from '@/http/api'
import wordDetaiEdit from './components/city_word_edit'
export default {
  components: {
    wordDetaiEdit
  },
  data () {
    return {
      dataList: [],
      editWordDetailForm: {
        spell: '',
        purl: '',
        def: '',
        durl: '',
        exp: '',
        eurl: '',
        phonetic: '',
        difficulty: null,
        level: Number(this.$route.query.level),
        partSpeech: ''
      },
      difficulty_type: this.$userInfo.difficulty_type(),
      importWordExcel: {
        uploadUrl: this.$env.baseIP + 'cityQuestionWord/importExcel',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      isUploadFile: false, // 是否已选择上传文件
      importQueryForm: {
        raceSchedule: '4',
        paperId: this.$route.query.paperId,
        level: Number(this.$route.query.level)
      },
      importWordInfoRules: {
        level: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      editCharactersDialogVisible: false,
      emptyWordExcelDialogVisible: false,
      importWordExcelDialogVisible: false,
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      dict_Group: this.$userInfo.dict_group(),
      total: 0,
      queryForm: {
        season: this.$chnEngStatusCode.defaultSeason,
        paperId: this.$route.query.paperId,
        // raceSchedule: '4',
        spell: '',
        // level: Number(this.$route.query.level),
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  created () {
    this.searchResult()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    searchResult () {
      getCityQuestionWordList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 单词点击事件
    handleToWordVoice (voiceUrl) {
      this.$refs.audioPlayer.src = voiceUrl
      this.$refs.audioPlayer.play()
    },
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = 10
      this.searchResult()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.searchResult()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.searchResult()
    },
    // 批量导出
    downloadInfoList () {
      const downLoadUrl = this.$env.baseIP + 'cityQuestionWord/exportExcel'
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, this.queryForm)
    },
    // 下载字库模板
    async downloadWordExcelTemplate () {
      var downLoadUrl = this.$env.baseIP + 'cityQuestionWord/downloadExcel'
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, {})
    },
    // 上传文件超时
    importWordExcelExceed (files, fileList) {
      console.log('fileList', files, fileList)
      this.$set(fileList[0], 'raw', files[0])
      this.$set(fileList[0], 'name', files[0].name)
      this.$refs.importWordExcelRef.clearFiles()// 清除文件
      this.$refs.importWordExcelRef.handleStart(files[0])// 选择文件后的赋值方法
    },
    // 上传文件加载中
    importWordExcelProgress () {
    },
    // 上传文件成功回调
    importWordExcelSuccess (res) {
      console.log('importWordExcelSuccess', res)
      this.$curUtils.closeLoading(this)
      this.isUploadFile = false
      if (res.code !== 200) {
        this.$refs.importWordExcelRef.clearFiles()// 清除文件
        return this.$alert('请修改后再上传，失败原因:' + res.msg, '上传失败', {
          confirmButtonText: '确定',
          type: 'warning',
          dangerouslyUseHTMLString: true
        })
      }
      this.$message.success('上传内容成功')
      this.importWordExcelDialogVisible = false
      this.searchResult()
      this.$refs.importWordExcelRef.clearFiles()// 清除文件
    },
    // 上传文件失败回调
    importWordExcelError (error) {
      console.log('importWordExcelError', error)
      this.$curUtils.closeLoading(this)
      this.$message.error('上次文件失败')
    },
    // 监听上传文件弹窗关闭事件
    importWordExcelDialogClose () {
      this.isUploadFile = false
    },
    // 上传前判断文件是否为空
    uploadWordExcel () {
      if (this.isUploadFile === true) {
        this.$refs.importWordExcelRef.submit()
      } else {
        this.$message.error('请先选择要上传的文件')
      }
    },
    // 监听上传文件变化
    onChangeLoad (files, fileList) {
      if (fileList.length > 0) {
        this.isUploadFile = true
      }
    },
    emptyWordExcelDialogClose () {
      this.$refs.emptyWordInfoFormRef.resetFields()
    },
    // 删除
    deleteDialog (rowId) {
      this.$confirm('是否确认删除该单词?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        deleteCityQuestionWord({ id: rowId }).then((res) => {
          this.searchResult()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    // 清空本组别单词
    saveEmptyQueryClick () {
      this.$confirm('是否确认删除该组别下所有单词?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        emptyCityQuestionWord(this.importQueryForm).then((res) => {
          this.searchResult()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    // 修改单词
    editDistrictMessage (type, rowData) {
      console.log('type', type)
      console.log('rowData', rowData)
      if (type === 'edit') {
        cityQuestionWord({ id: rowData.id }).then((res) => {
          this.editWordDetailForm = res.data
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }
      this.editCharactersDialogVisible = true
    },
    closeFn () {
      this.editCharactersDialogVisible = false
    },
    overAudio () {
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_Group) {
        if (Object.hasOwnProperty.call(this.dict_Group, key)) {
          const element = this.dict_Group[key]
          if (element.dictValue === data.level) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    difficultyFormatter (data) {
      var temp = '未设置'
      for (const key in this.difficulty_type) {
        if (Object.hasOwnProperty.call(this.difficulty_type, key)) {
          const element = this.difficulty_type[key]
          if (element.dictValue === data.difficulty) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>

<style>
</style>
